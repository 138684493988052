@import 'variable';
@import 'animations';

// BOOTSTRAP
@import "../../node_modules/bootstrap/scss/bootstrap";

.progress {
    height: 12px;
    overflow: inherit;
    border-radius: 20px;
    padding: 0 24px;
    background: linear-gradient(90deg, $color-primary 24px, mix($color-base, white, 10%) 44px);

    .progress-bar {
        background-color: $color-primary;
        border-radius: 15px;
        overflow: inherit;
        position: relative;

        span.progress-btn {
            cursor: pointer;
            width: 48px;
            height: 24px;
            border-radius: 30px;        
            background-color: #4e7ac7;
            position: absolute;
            right: -24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;

            &._anim {

                &::before {
                    display: block;
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #4e7ac7;
                    border-radius: 30px;
                    transform-origin: center;

                    animation: splash linear 5s;
                    animation-iteration-count: infinite;
                    transform-origin: 50% 50%;
                    -webkit-animation: splash linear 5s;
                    -webkit-animation-iteration-count: infinite;
                    -webkit-transform-origin: 50% 50%;
                    -moz-animation: splash linear 5s;
                    -moz-animation-iteration-count: infinite;
                    -moz-transform-origin: 50% 50%;
                    -o-animation: splash linear 5s;
                    -o-animation-iteration-count: infinite;
                    -o-transform-origin: 50% 50%;
                    -ms-animation: splash linear 5s;
                    -ms-animation-iteration-count: infinite;
                    -ms-transform-origin: 50% 50%;
                }
            }
        }
    }
}


.badge {
    border-radius: 14px;
    padding: 6px 12px;
    color: white;
}

.bg-primary {
    background-color: $color-primary !important;
}

.bg-success {
    background-color: $color-success !important;
}

.bg-danger {
    background-color: $color-error !important;
}

.alert-success {
    color: $color-success;
    background-color: mix($color-success, white, 10%);
    border-color: mix($color-success, white, 100%);
}

.alert-danger {
    color: $color-error;
    background-color: mix($color-error, white, 10%);
    border-color: mix($color-error, white, 100%);
}

.alert-warning {
    color: $color-warning;
    background-color: mix($color-warning, white, 10%);
    border-color: mix($color-warning, white, 100%);
}

.alert-dismissible .close {
    padding: 10px 14px;
}

.modal-dialog {
    margin: 0.5rem 36px;

    @media (max-width: 350px) {
        margin: 0.5rem 18px;
    }

    @media (min-width: 576px) {
        margin: 1.75rem auto;
        max-width: 468px;
    }
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 4px;
    box-shadow: $shadow;

    padding: 24px 16px;

    .link-btn-list {
        text-align: center;
        list-style: none;
        padding: 0;

        li {
            display: block;

            &:not(:last-child) {
                margin-bottom: 22px;
            }

            a {
                color: $color-base;
                font-weight: bold;
                font-size: 18px;
                text-decoration: none;
            }
        }
    }

    .conf-dialog {

        &__img {
            width: 100%;
            height: auto;
            margin-bottom: 24px;
        }

        &__title {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 32px;
            text-align: center;
            line-height: 1.4;

            span {
                // color: $color-primary;
                font-size: 22px;

                &._good {
                    color: $color-success;
                }
                
                &._bad {
                    color: $color-error;
                }
            }
        }

        &__btn {
            display: flex;
            justify-content: center;

            > * {

                &:first-child {
                    margin-right: 5px;
                }

                &:nth-child(2) {
                    margin-left: 5px;
                }
            }
        }

        &__table {
            height: calc(100vh - 400px);
            overflow-y: auto;
            margin-bottom: 24px;

            table {
                width: 100%;
    
                tr {
                    display: block;
                    margin-bottom: 16px;
    
                    th {
                        display: block;
                    }
    
                    td {
    
                    }
                }
            }
        }
    }
}

.nav-tabs--custom {
    border-bottom: none;

    .nav-item {

        .nav-link {
            text-align: center;
            background-color: #EFEFEF;
            border-radius: 21px;
            font-weight: bold;
            color: $color-base;
            transition: all .2s ease-in-out;

            &.active {
                background-color: #F8D8DE;
            }
        }
    }
}

.tab-content--custom {
    margin-top: 24px;
}

// Summernote customize
.note-editor {
    position: relative;

    &.card {
        border: 1px solid #cdcdcd !important;
        border-radius: 0;
    }

    .note-editor.note-frame, .note-editor.note-airframe {
        border: 1px solid #cdcdcd !important;
    }
    
    .card-header {
        background-color: white;
    }

    .btn-sm, .btn-group-sm > .btn {
        border-radius: 0;
    }

    &.note-frame .note-statusbar .note-resizebar, .note-editor.note-airframe .note-statusbar .note-resizebar {
        background-color: white;
    }
}